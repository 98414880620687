import React, { useEffect, useState } from 'react'
import { CourseDescCard } from '../elements/courseSessionCard'
import Header from '../elements/header'
import FeedbackCard from '../elements/feedbackCard'
import FeedbackCard1 from '../elements/feedbackcard1'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import liTitltIcon from '../../images/liIcon.svg'
import liIcon from '../../images/liTitleIcon.svg'
import { createFeedback } from '../../function/Student/Feedback/Feedback'
// import { uuid } from '../../redux/useReducer'
import AbsentReportCard from '../elements/absentReportCrd'
import thumbsup from '../../images/thumbsup.svg'
import closeicon from '../../images/close-red-icon.svg'
import Footer from '../elements/Footer'


function Coursedescpage() {
  const uuid:any = localStorage.getItem('uid')
  const { state } = useLocation()
  const navigate = useNavigate()
  const [courseStatus] = useState(state)
  const [feedbackpopup, setFeedbackpopup] = useState(false)
  const [displayfield, setDisplayfield] = useState(true)
  const [isvisible, setIsvisible] = useState('hidden')
  const [isvisible1, setIsvisible1] = useState('hidden')
  const [isvisible2, setIsvisible2] = useState('hidden')
  

  const [sessionmsg, setSessionmsg] = useState(courseStatus.sessionMessage)
  const courseData = courseStatus?.data
  const SessionId = courseStatus?.sessionData?.id
  const SessionBatchId = courseStatus?.data?.alteredData ? courseStatus?.data?.alteredData?.training_id : courseStatus?.data?.courseDetails?.training_id


  const [feedbackdata, setFeedbackdata] = useState({
    message: '',
    feedbacktype: '',
    color:''
  })

  const [feedbackdata1, setFeedbackdata1] = useState({
    feedbacktype: '',
    color: '',
    message :''
  })

  const [feedbackdata2, setFeedbackdata2] = useState({
    message: '',
    feedbacktype: ''
  })


  const handelbtn = (data: string) => {

    const colors:any = {
      'Excellent':'#3C9419',
      'Good':'#3C9419',
      'Average':'#FFD500',
      'Poor':'#FF0000'
    }
    let msg = ''
    if (data === 'Excellent' || data==='Good')  {
      // createFeedback({ response: "Excellent", student_id: uuid, session_id: SessionId, training_id: SessionBatchId })
      setIsvisible2('hidden')
      setIsvisible('block')
      setIsvisible1('hidden')
      msg = 'Thanks for your valuable Feedback'
    }
     else {
      msg = 'Your Concern is sent to the respective Manager. Be assured that our team will get in touch with you with in 1 working day'
      setIsvisible('hidden')
      setIsvisible2('hidden')
      setIsvisible1('block')
    }

    setDisplayfield(false)
    setFeedbackpopup(true)
    setFeedbackdata({
      message: msg,
      feedbacktype: data,
      color:colors[data]
    })

  }

  // const handelbtn1 = (data: string) => {

  //   if (data === 'Average') {
  //     setFeedbackdata1({
  //       feedbacktype: 'Average',
  //       color: '#FFD500',
  //       message : 'Your Concern is sent to the respective Manager. Be assured that our team will get in touch with you with in 1 working day'

  //     })
  //     setIsvisible('hidden')
  //     setIsvisible2('hidden')
  //     setIsvisible1('block')

  //   }
  //   else {
  //     setFeedbackdata1({
  //       feedbacktype: 'Poor',
  //       color: "#FF0000",
  //       message : 'Your Concern is sent to the respective Manager. Be assured that our team will get in touch with you with in 1 working day'

  //     })
  //     setIsvisible('hidden')
  //     setIsvisible2('hidden')
  //     setIsvisible1('block')
  //   }

  //   setDisplayfield(false)
  //   setFeedbackpopup(true)
  // }

  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0
    })
    if (state.stateValue) {
      setDisplayfield(true)
      state.stateValue = false
    }
  }, [])

  const handelPresent = () => {
    setIsvisible('hidden')
    setIsvisible1('hidden')
    setIsvisible2('block')
    setDisplayfield(false)
    setFeedbackpopup(true)
  }


  const onClickhandle = () => {
    setOnsubmit1(true)
    setOnsubmit(true)
    setDisplayfield(true)
  }
  const [onsubmit, setOnsubmit] = useState(true)

  const handelSubmit = (props:any) => {
    createFeedback({ response: props, student_id: uuid, session_id: SessionId, training_id: SessionBatchId, comment:message })
    setOnsubmit(false)
    navigate(-1)
  }

  const [onsubmit1, setOnsubmit1] = useState(true)

  const handelSubmit1 = (props:any) => {
    createFeedback({ response: "Absent", student_id: uuid, session_id: SessionId, training_id: SessionBatchId,comment:message })
    // console.log(SessionId,SessionBatchId, message, props)
    setOnsubmit1(false)
  }

  const [message, setMessage] = useState('');

  const handleMessageChange = (event:any) => {
    // 👇️ access textarea value
    setMessage(event.target.value);
  };
  return (
    <div className='bg-[#F9FCFF] min-h-screen max-mobile:h-[78vh] pb-5'>
      <Header />
      {displayfield ? <div className=''>
        <CourseDescCard title='Course Progress' courseName={courseData?.alteredData?courseData?.alteredData?.course_name : courseData?.courseDetails?.course_name} trainer={courseData?.alteredData ? courseData?.alteredData?.trainer_name : courseData?.courseDetails?.trainer_name} branch={courseData?.alteredData ? courseData?.alteredData?.branch : courseData?.courseDetails?.branch_name} city={courseData?.alteredData ? courseData?.alteredData?.city : courseData?.courseDetails?.city} date={courseData?.alteredData ? courseStatus.date : courseData?.courseDetails?.date} />
        <div className=' flex flex-col pl-96 max-monitor:pl-96 max-desktop:pl-52 max-laptop:pl-8 max-mobile:pl-0 '>
          <div className='text-left my-5 ml-1 max-laptop:ml-20 max-mobile:ml-10'>
            <div className='flex text-[27px] max-tablet:text-[27px] font-medium text-[#707070] max-mobile:text-[18px]'> You are Marked <span className='text-[#036AD1]'> {courseStatus?.sessionData?.is_present === true ? <p className=' text-[#036AD1] mx-1'> Present</p> : <p className='text-[#FB0000] mx-1'>Absent</p>} </span> for the Session </div>
            <div><p className='text-[30px] font-medium text-[#FB0000] max-tablet:text-[30px] max-mobile:text-[18px]'> {courseStatus?.sessionData?.title}</p> </div>
          </div>
          <div className='text-left ml-6 max-monitor:pl-4 max-desktop:pl-10 max-laptop:pl-8 max-mobile:'>
            <p className='flex text-[20px] max-tablet:text-[20px] max-mobile:text-[16px] font-medium items-center'> <img src={liTitltIcon} alt='icon' className='w-5 h-5 mr-2' /> {courseStatus?.sessionData?.details?.topic}</p>
            <ul className='pb-2 ml-10 my-5'>
              {courseStatus?.sessionData?.details?.subtopics.map((item: any) => {
                return <li className='flex items-center  text-[20px] max-tablet:text-[20px] max-mobile:text-[16px] font-medium'> <img className='w-3 h-3 mr-3' src={liIcon} alt='icon' />{item} </li>
              })}
            </ul>
          </div>
        </div>
    {sessionmsg === 'Give Feedback' ? 
        <div className='max-mobile:flex justify-center
        '> 
          <button className="text-white text-center mx-2 my-2 w-32 max-tablet:w-32 max-mobile:w-20 bg-[#3C9419] rounded-sm font-medium  text-sm px-5 max-tablet:px-5 max-mobile:px-1 py-2.5 " onClick={() => handelbtn('Excellent')}>Excellent</button>
          <button className="text-white text-center mx-2 my-2 w-32 max-tablet:w-32 max-mobile:w-20 bg-[#55AB33] rounded-sm font-medium  text-sm px-5 max-tablet:px-5 max-mobile:px-1 py-2.5 " onClick={() => handelbtn('Good')}> Good</button>
          <button className="text-[#000000] text-center mx-2 my-2 w-32 max-tablet:w-32 max-mobile:w-20 bg-[#FFD500] rounded-sm font-medium  text-sm max-tablet:px-5 max-mobile:px-1 px-5 py-2.5 " onClick={() => handelbtn('Average')}>Average</button>
          <button className="text-white text-center mx-2 my-2 w-32 max-tablet:w-32 max-mobile:w-20 bg-[#FF0000] rounded-sm font-medium  text-sm max-tablet:px-5 max-mobile:px-1 px-5 py-2.5 " onClick={() => handelbtn('Poor')}>Poor</button>
        </div>
          : <div></div> 
        }  
        <div className='my-5 text-[17px] max-tablet:text-[17px] max-mobile:text-[14px] max-tablet:my-5 max-mobile:my-8 text-[#707070]'> If you were {courseStatus?.sessionData?.is_present ? 'Absent' : 'Present'}<span onClick={() => handelPresent()} className=' bg-[#036AD1]  text-[#FFFFFF] cursor-pointer ml-1 rounded-sm p-2'> Report Here</span></div>

      </div> : 
      <div className=" grid items-center bg-[#F9FCFF] h-[65vh] justify-evenly mx-auto ">
        {/* <div className={`text-lg ${isvisible}`} >
          <div className={`block align-middle mx-auto text-center relative  justify-center p-6 bg-white border border-gray-200 rounded-lg shadow `}>
            <img className='cursor-pointer  right-5  top-3 absolute mb-5 w-6 h-6 ' onClick={onClickhandle} src={closeicon} alt='closeicon' />
            <button className=" bg-[#55AB33] text-[#ffffff] py-1 rounded-md outline-none px-3 border-r-2  cursor-default"> {feedbackdata.feedbacktype}</button>
            <div className="flex text-[#707070] mx-auto my-5 pl-4">{feedbackdata.message} <img className='w-6 h-6 ml-5' src={thumbsup} alt='thumbsUpicon' /></div>
          </div>
        </div> */}

        <div className={`text-lg ${isvisible}`} >
          <div className={`block align-middle mx-auto text-center relative w-[400px]  justify-center p-6 bg-white border border-gray-200 rounded-lg shadow `}>
            <img className='cursor-pointer  right-5  top-3 absolute mb-5 w-6 h-6 ' onClick={onClickhandle} src={closeicon} alt='closeicon' />
            {onsubmit ? <div>
              {feedbackdata.feedbacktype == 'Excellent' ? <button className="cursor-default px-3 rounded-md text-[#fff]" style={{ backgroundColor: feedbackdata.color }}> {feedbackdata.feedbacktype}</button> : <button className=" px-3 cursor-default  rounded-md text-[#ffffff]" style={{ backgroundColor: feedbackdata.color }}> {feedbackdata.feedbacktype}</button>}
              <textarea className="p-2 my-3 block w-[350px] h-24 border text-left text-[#636363] justify-start text-[15px]  font-medium" placeholder='Write a Concern '  onChange={handleMessageChange}  />
              <button type="button" className="my-4  text-white bg-[#036AD1] hover:bg-[#036AD1] font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 " onClick={()=>handelSubmit(feedbackdata.feedbacktype)}>Submit</button>
            </div> : <div className=''>
              <p className="m-3 ">{feedbackdata.message}</p>
            </div>}
          </div>
        </div>


        <div className={`text-lg ${isvisible1}`} >
          <div className={`block align-middle mx-auto text-center relative w-[400px]  justify-center p-6 bg-white border border-gray-200 rounded-lg shadow `}>
            <img className='cursor-pointer  right-5  top-3 absolute mb-5 w-6 h-6 ' onClick={onClickhandle} src={closeicon} alt='closeicon' />
            {onsubmit ? <div>
              {feedbackdata.feedbacktype == 'Average' ? <button className="cursor-default px-3 rounded-md text-[#000000]" style={{ backgroundColor: feedbackdata.color }}> {feedbackdata.feedbacktype}</button> : <button className=" px-3 cursor-default  rounded-md text-[#ffffff]" style={{ backgroundColor: feedbackdata.color }}> {feedbackdata.feedbacktype}</button>}
              <textarea className="p-2 my-3 block w-[350px] h-24 border text-left text-[#636363] justify-start text-[15px]  font-medium" placeholder='Write a Concern '  onChange={handleMessageChange}  />
              <button type="button" className="my-4  text-white bg-[#036AD1] hover:bg-[#036AD1] font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 " onClick={()=>handelSubmit(feedbackdata.feedbacktype)}>Submit</button>
            </div> : <div className=''>
              {/* <p className="m-3 "> */}
              <p className="m-3 ">
            <p className="m-3 ">{feedbackdata.message}</p>

                </p>
               
               {/* <p>Your Concern is sent to the respective Manager. Be assured that our team will get in touch with you with in 1 working day</p> */}
            </div>}
          </div>
        </div>
        <div className={`text-lg ${isvisible2}`} >
          <div className={`block align-middle mx-auto text-center relative  w-[400px] justify-center p-6 bg-white border border-gray-200 rounded-lg shadow  `}>
            <img className='cursor-pointer  right-5  top-3 absolute mb-5 w-6 h-6 ' onClick={onClickhandle} src={closeicon} alt='closeicon' />
            {onsubmit1 ? <div className='mt-10 '>
              <textarea className="p-2 my-3 block w-[350px] h-24 border text-left text-[#636363] justify-start text-[15px]  font-medium" placeholder='Write a Concern '  onChange={handleMessageChange} />
              <button type="button" className="my-4 text-white bg-[#036AD1]  hover:bg-[#036AD1] font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 " onClick={()=>handelSubmit1(feedbackdata.feedbacktype)} >Submit</button>
            </div> : <div >
               {/* <p>Your Concern is sent to the respective Manager. Be assured that our team will get in touch with you with in 1 working day</p> */}
            <p className="m-3 ">
              Your Concern is sent to the respective Manager. Be assured that our team will get in touch with you with in 1 working day
            </p>
            </div>}
          </div>
        </div>
      </div>
      }
      <Footer/>
    </div>
  )
}

export default Coursedescpage